<template>
  <div class="pop-up-users-view-container">
    <div class="pop-up-users-view">
      <div class="pop-up-users-view-title">
        <p>
          Mensajes vistos por
        </p>
        <div @click="toggleState('popUpViewMessagesByUsers')">
          <iconic class="close-icon" name="close" />
        </div>
      </div>
      <div class="pop-up-users-view-description">
        <span class="pop-up-users-views">{{ views.usersView.length }} visualizaciones</span>
        <li class="pop-up-user" v-for="u in views.usersView" :key="u.id">
          <img class="msg-image" :src="u.picture" :alt="u.picture" />
          <span class="pop-up-user-name">{{ u.user }}</span>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["views"],
  methods: {
    ...mapActions("chat", {
      toggleState: "toggleState",
    }),
  },
};
</script>

<style lang="scss">
.pop-up-users-view-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 40px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  .pop-up-users-view {
    background-color: white;
    width: 300px;
    border-radius: 11px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
    .pop-up-users-view-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      p {
        font-size: 14px;
        margin-left: 10px;
        padding: 10px 0;
        color: #bd0909;
        font-weight: 600;
      }
      div {
        margin-right: 15px;
        cursor: pointer;
        .close-icon {
          font-weight: 600;
        }
      }
    }
    .pop-up-users-view-description {
      max-height: 126px;
      overflow-y: auto;
      padding: 0 10px;
      padding-bottom: 15px;
      border-bottom-left-radius: 11px;
      border-bottom-right-radius: 11px;
      .pop-up-users-views {
        display: inline-block;
        width: 100%;
        text-align: right;
        color: #5b5957;
        font-size: 10px;
      }
      .pop-up-user {
        list-style: none;
        padding: 5px 0;
        border-bottom: 1px solid #e9e9e9;
        .pop-up-user-name {
          margin-left: 15px;
          color: #5b5957;
        }
        .msg-image {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
    .pop-up-users-view-description::-webkit-scrollbar {
      width: 6px;
    }
    .pop-up-users-view-description::-webkit-scrollbar-thumb {
      background-color: #bd0909;
      border-radius: 10px;
    }
  }
}
</style>
